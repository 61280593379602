import React, { Component } from 'react';
import BootstrapCarouselDemo from './carousel2';
import { Link, Routes, Route } from 'react-router-dom';
export class Main extends Component {
  render() {
    return (
      <div>
        <div className='absolute   h-screen w-screen overflow-hidden'>
          <div className='relative'>
            <BootstrapCarouselDemo></BootstrapCarouselDemo>
          </div>
          <Link
            className='fixed left-8 bottom-8 text-xs hover:text-gray-100 font-sans text-gray-100 z-20'
            to='/impressum'
          >
            Impressum
          </Link>
          <div className='fixed  -translate-y-2/4 -translate-x-2/4 w-[85] font-sans text-center text-gray-100 z-20 left-1/2 top-1/4 md:top-1/2'>
            <span className=' min-w-fit text-2xl font-bold'>
              WIR PLANEN LEBENSRÄUME
            </span>
            <br />
            <p className=' min-w-fit text-xs'>
              Wenn sie mehr erfahren wollen melden Sie sich bei uns.
            </p>
          </div>

          <div className=' fixed text-sm z-20 portrait:right-1/2 portrait:bottom-1/4    portrait:translate-y-2/4 portrait:translate-x-2/4 md:right-16 md:bottom-8  md:translate-none font-sans text-left text-gray-100'>
            <p>
              <span className='font-bold '>
                Architekturbüro <br /> Huppenbauer + Engel
              </span>
              <br />
              Inhaber M. Huppenbauer (FH) <br />
              Kochenmühle 2 <br />
              70771 Leinfelden-Echterdingen <br /> 07157 / 737930 <br />
              hue@huppenbauer-engel.de
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
