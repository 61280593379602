import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import img1 from './imgs/field.jpg';
import img2 from './imgs/field2.jpg';
import img3 from './imgs/flowers.jpg';
import Image from 'react-bootstrap/Image';
import img5 from './imgs/turkey.jpg';
import img6 from './imgs/sea2.jpg';
import img8 from './imgs/mountains.jpg';
import img9 from './imgs/road66.jpg';
import img4 from './imgs/newsealand.jpg';
import img10 from './imgs/desert.jpg';
import img11 from './imgs/road.jpg';
import img12 from './imgs/forest2.jpg';
import img13 from './imgs/sky.jpg';
import img14 from './imgs/Pura-Vida.JPG';
import img15 from './imgs/Holzwarth Innen 2.jpg';
import img16 from './imgs/office.jpg';
import img17 from './imgs/Pura-vida1.JPG';
import img18 from './imgs/HolzwarthAussen.jpg';
import img19 from './imgs/dach.jpg';

export class BootstrapCarouselDemo extends Component {
  constructor(props) {
    super(props);
    this.images = [
      img1,
      img2,
      img3,
      img5,
      img6,
      img8,
      img9,
      img4,
      img10,
      img11,
      img12,
      img13,
      img14,
      img15,
      img16,
      img17,
      img18,
      img19
    ];
    this.mobile = {
      flex: '1',
      height: '100%',
      width: 'undefined',
      alignSelf: 'contain'
    }; // I declare the variable here
    this.carItemMobil = {
      flex: '1',
      height: '100vh',
      width: '100vw',
      alignSelf: 'cover'
    };
    this.carItemDesktop = { height: '100vh' };
    this.imageDesktop = { width: '100vw', alignSelf: 'contain' };
    this.carouselItemStyle = {};
    this.imageStyle = {};
    this.becomeFluid = false;

    if (window.matchMedia('(orientation: portrait)').matches) {
      console.log('mobil');
      console.log(window.orientation);
      this.carouselItemStyle = this.carItemMobil;
      this.imageStyle = this.mobile;
      this.becomeFluid = false;
    } else {
      console.log('Desktop');
      console.log(window.orientation);
      this.carouselItemStyle = this.carItemDesktop;
      this.imageStyle = this.imageDesktop;
      this.becomeFluid = true;
    }
  }
  render() {
    return (
      <div>
        <Carousel
          fade={true}
          interval={4000}
          keyboard={true}
          pauseOnHover={false}
          indicators={false}
          controls={true}
        >
          <Carousel.Item className='w-screen h-screen'>
            <Image
              alt='Image'
              src={img3}
              className='object-cover w-full h-full'
            />
          </Carousel.Item>
          {this.images.map(x => (
            <Carousel.Item className='w-full h-screen'>
              <Image
                alt='Image'
                src={x}
                className='object-cover w-full h-full'
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }
}

export default BootstrapCarouselDemo;
