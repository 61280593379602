import React, { Component } from 'react';
import { Link, Routes, Route, BrowserRouter } from 'react-router-dom';
import Impressum from './impressum';
import Main from './main';

import './App.css';

export class App extends Component {
  render() {
    return (
      <div className='App'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Main />}></Route>
            <Route path='impressum' element={<Impressum />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
