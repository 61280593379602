import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Impressum extends Component {
  render() {
    return (
      <div>
        <p className='fixed top-1/2 left-1/2 text-sans -translate-y-2/4 -translate-x-2/4'>
          <h6 className='name'>Architekturbüro Huppenbauer + Engel</h6>
          <br></br>Kochenmühle 2<br></br>D-70771 Leinfelden-Echterdingen
          <br></br>Inhaber M. Huppenbauer (FH)
          <br></br>
          <br></br>
          <b>Telefon</b> 07157 - 73 79 30<br></br>
          <b>Telefon</b> 07157 - 73 79 45<br></br>
          <br></br>
          <br></br>
          <b>Email </b> hue@huppenbauer-engel.de<br></br>
          <b>Website </b>www.huppenbauer-engel.de<br></br>
          <br></br>
          <br></br>
          <b>Inhaber </b>
          Michael Huppenbauer<br></br>
          Eingetragenes Mitglied der Architektenkammer Baden-Württemberg
          <br></br>
          AL-Nummer 33949
          <br></br>
          <br></br>
          <b>Umsatzsteuer ID: </b>
          DE 291 370 287
        </p>

        <Link
          className='fixed left-8 bottom-8 text-xs hover:text-gray-900 font-sans text-gray-900 z-20'
          to='/'
        >
          Zurück
        </Link>
      </div>
    );
  }
}

export default Impressum;
